import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "nateVick",
  "categories": ["iac", "cdk"],
  "date": "2021-08-21",
  "path": "/blog/deploy-infrastructure-cdktf",
  "summary": "Create and deploy reproducible infrastructure with CDKTF",
  "title": "Deploy Infrastructure With CDKTF",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@natron99"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`CDK or Cloud Development Kit came out of AWS in 2018 as a way to write Infrastructure as Code in software languages used day-to-day by developers (JavaScript/TypeScript, Python, Java, C#, and soon Go). Since its release, a community has built up around it, and new flavors have arrived. AWS CDK, CDKTF, and CDK8s are all based on the same core, but compile to different formats. AWS CDK compiles to CloudFormation, while CDKTF compiles to Terraform compatible JSON, and CDK8s compiles to Kubernetes config.`}</p>
    <p>{`In this post, we will walk through how to use CDKTF with DigitalOcean's Terraform provider. A Terraform provider is a "plugin" for Terraform to interact with remote systems. In this case, the provider is created and maintained by DigitalOcean. We will share a few examples of creating a Digital Ocean Project, a VPC, a Postgres Database, and a Droplet.`}</p>
    <h2>{`Prereqs and Install`}</h2>
    <p>{`To use cdktf you will need the following packages installed:`}</p>
    <ul>
      <li parentName="ul">{`Terraform ≥ 0.12`}</li>
      <li parentName="ul">{`Node.js ≥ 12.16`}</li>
      <li parentName="ul">{`Yarn ≥ 1.21`}</li>
    </ul>
    <p>{`To install cdktf, we will use `}<inlineCode parentName="p">{`npm install`}</inlineCode></p>
    <p><inlineCode parentName="p">{`$ npm install --global cdktf-cli`}</inlineCode></p>
    <p>{`You will also need to `}<a parentName="p" {...{
        "href": "https://docs.digitalocean.com/reference/api/create-personal-access-token/"
      }}>{`create a personal access token`}</a>{` on Digital Ocean if you would like to deploy this config.`}</p>
    <h2>{`Create and Initialize the example project`}</h2>
    <p>{`First let's make a directory and change into it.`}</p>
    <p><inlineCode parentName="p">{`$ mkdir cdk-do-example && cd cdk-do-example`}</inlineCode></p>
    <p>{`Initialize the project with the `}<inlineCode parentName="p">{`init`}</inlineCode>{` command. In general, I use the `}<inlineCode parentName="p">{`--local`}</inlineCode>{` flag, so all state is stored locally.`}</p>
    <p><inlineCode parentName="p">{`$ cdktf init --template=typescript --local`}</inlineCode></p>
    <p>{`You will be prompted for Project Name and Description but defaults are fine.`}</p>
    <p>{`The last step of setting up the project is to add Terraform providers to the `}<inlineCode parentName="p">{`cdktf.json`}</inlineCode>{`. Open the project in your editor and let's add the DigitalOcean provider to it as follows.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"language"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"typescript"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"npm run --silent compile && node main.js"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"terraformProviders"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"digitalocean/digitalocean@~> 2.9"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"terraformModules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"context"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"excludeStackIdFromLogicalIds"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"true"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"allowSepCharsInLogicalIds"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"true"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Install Dependencies`}</h2>
    <p>{`Run `}<inlineCode parentName="p">{`cdktf get`}</inlineCode>{` to download the dependencies for using DigitalOcean with Typescript.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ cdktf get
Generated typescript constructs `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` the output directory: .gen`}</code></pre></div>
    <h2>{`Let's Write Some TypeScript`}</h2>
    <p>{`Open `}<inlineCode parentName="p">{`main.ts`}</inlineCode>{` in your editor and let's start by creating a DO Project.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Construct `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'constructs'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` App`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` TerraformStack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Token `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cdktf'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` DigitaloceanProvider `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Project `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStack`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TerraformStack`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`scope`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Construct`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` name`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`scope`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DigitaloceanProvider`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'digitalocean'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      token`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Token`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`asString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`process`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`env`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`DO_TOKEN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Project`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-project'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Example Rails Project'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` app `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`App`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStack`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cdk-do-example'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`synth`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`You will create a new `}<inlineCode parentName="p">{`DigitaloceanProvider`}</inlineCode>{` and pass in the environment variable assigned to your DO personal access token. Next, we create the `}<inlineCode parentName="p">{`Project`}</inlineCode>{`, which has a required key of `}<inlineCode parentName="p">{`name`}</inlineCode>{`.`}</p>
    <p>{`Optionally, as a test of the code above, run `}<inlineCode parentName="p">{`cdktf deploy`}</inlineCode>{` to deploy your CDKTF project. The cli will ask if you want to make the changes listed under Resources. Type 'yes', then once it finishes, you will see a green check next to the Resources it successfully created.`}</p>
    <p>{`cli prompt:
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30.061349693251532%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxUlEQVQY03WOa2rDMBAGfZrElfVeaa235BqnaQi5/3GCYpqCS2H42D+zzNC2R9se+fPm81bWO1N+ZJZwJBxHakZqPpj9j8G4hmHVWJTN0iQBEVxTNmusHALhSKWbxPwXwnHAuFLpBET5lufKdZDQbyrdiejzBOe+em95M8xpC+XLl4svl7zcQr3GnXYVkJjyHKJ4PWI6HGVhkrJZQNRYlS3SZpibND1bY+3N0tEXk5iPsrTFuEVjSe3b+uVEftt67b4/HOQnYmlIGEcc6scAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Agree to deployment",
          "title": "Agree to deployment",
          "src": "/static/36c8638b60184ea64006451ef1f83d4a/a6d36/enter-value.png",
          "srcSet": ["/static/36c8638b60184ea64006451ef1f83d4a/222b7/enter-value.png 163w", "/static/36c8638b60184ea64006451ef1f83d4a/ff46a/enter-value.png 325w", "/static/36c8638b60184ea64006451ef1f83d4a/a6d36/enter-value.png 650w", "/static/36c8638b60184ea64006451ef1f83d4a/e548f/enter-value.png 975w", "/static/36c8638b60184ea64006451ef1f83d4a/3c492/enter-value.png 1300w", "/static/36c8638b60184ea64006451ef1f83d4a/f1c64/enter-value.png 1390w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`successful deployment:
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "12.883435582822086%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAcElEQVQI132NSwoCMRAFcxs/iekmeZO0+djRILOZ+59HHMGdQi0eRcEzOrcoIySV9iy6Fl1vc7OUT5fl6PAfg+uD0QmN0R2Lo+xD9bHy0j+FY7GUv5x92s1bGkajWCH3kJTRUpn76JABGY7lYH8+vwAEoiO1r4PhLwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "First deployment",
          "title": "First deployment",
          "src": "/static/a0c6aa81007768934b67e4471fba5b37/a6d36/first-deploy.png",
          "srcSet": ["/static/a0c6aa81007768934b67e4471fba5b37/222b7/first-deploy.png 163w", "/static/a0c6aa81007768934b67e4471fba5b37/ff46a/first-deploy.png 325w", "/static/a0c6aa81007768934b67e4471fba5b37/a6d36/first-deploy.png 650w", "/static/a0c6aa81007768934b67e4471fba5b37/e548f/first-deploy.png 975w", "/static/a0c6aa81007768934b67e4471fba5b37/3c492/first-deploy.png 1300w", "/static/a0c6aa81007768934b67e4471fba5b37/2cefc/first-deploy.png 1400w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now we will add a VPC, a Postgres Database, and a Droplet. Once those examples are in, we will tie it all together before deploying it again.`}</p>
    <h3>{`Create a VPC`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Construct `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'constructs'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` App`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` TerraformStack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Token `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cdktf'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` DigitaloceanProvider`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Droplet`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Vpc `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Project `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStack`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TerraformStack`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`scope`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Construct`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` name`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`scope`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DigitaloceanProvider`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'digitalocean'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      token`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Token`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`asString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`process`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`env`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`DO_TOKEN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`more code above

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Vpc`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-vpc'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-vpc'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      region`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'sfo3'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` app `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`App`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStack`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cdk-do-example'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`synth`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p><em parentName="p">{`*`}{`Note: If you do not have VPC in your account already this will become the default VPC which will not be deleted when cleaning up the CDKTF Project.`}</em></p>
    <h3>{`Create a Postgres Database`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Construct `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'constructs'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` App`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` TerraformStack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Token `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cdktf'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` DigitaloceanProvider`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Droplet`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Vpc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` DatabaseCluster`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` DatabaseUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` DatabaseDb `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Project `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStack`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TerraformStack`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`scope`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Construct`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` name`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`scope`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DigitaloceanProvider`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'digitalocean'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      token`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Token`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`asString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`process`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`env`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`DO_TOKEN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`more code above

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` postgres `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DatabaseCluster`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-postgres'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-postgres'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      engine`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'pg'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      version`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'13'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'db-s-1vcpu-1gb'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      region`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'sfo3'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      nodeCount`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DatabaseUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-postgres-user'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      clusterId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`postgres`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`id`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DatabaseDb`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-postgres-db'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      clusterId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`postgres`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`id`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-db'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` app `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`App`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStack`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cdk-do-example'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`synth`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Notice we assigned the `}<inlineCode parentName="p">{`DatabaseCluster`}</inlineCode>{` to a variable `}<inlineCode parentName="p">{`const postgres`}</inlineCode>{`. We then use the variable to create the `}<inlineCode parentName="p">{`DatabaseUser`}</inlineCode>{` and `}<inlineCode parentName="p">{`DatabaseDb`}</inlineCode>{` on that cluster.`}</p>
    <h3>{`Create a Droplet`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Construct `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'constructs'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` App`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` TerraformStack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Token `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cdktf'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` DigitaloceanProvider`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Droplet`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Vpc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` DatabaseCluster`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` DatabaseUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` DatabaseDb `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Project `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStack`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TerraformStack`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`scope`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Construct`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` name`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`scope`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DigitaloceanProvider`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'digitalocean'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      token`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Token`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`asString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`process`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`env`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`DO_TOKEN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`more code above

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Droplet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-droplet'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-droplet'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'s-1vcpu-1gb'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      region`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'sfo3'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      image`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'ubuntu-20-04-x64'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` app `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`App`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStack`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cdk-do-example'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`synth`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2>{`Let's Put It All Together`}</h2>
    <p>{`If you run `}<inlineCode parentName="p">{`cdktf deploy`}</inlineCode>{` now, it would create everything, but nothing created would be put into the Digital Ocean project or the VPC we create. Let's do that now.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Construct `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'constructs'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` App`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` TerraformStack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Token `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cdktf'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` DigitaloceanProvider`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Droplet`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Vpc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` DatabaseCluster`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` DatabaseUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` DatabaseDb `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Project`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` ProjectResources `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./.gen/providers/digitalocean'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStack`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TerraformStack`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`scope`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Construct`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` name`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`scope`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DigitaloceanProvider`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'digitalocean'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      token`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Token`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`asString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`process`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`env`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`DO_TOKEN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` project `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Project`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-project'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Example Rails Project'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` vpc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Vpc`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-vpc'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-vpc'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      region`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'sfo3'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` postgres `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DatabaseCluster`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-postgres'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-postgres'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      engine`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'pg'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      version`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'13'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'db-s-1vcpu-1gb'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      region`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'sfo3'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      nodeCount`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      privateNetworkUuid`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` vpc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DatabaseUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-postgres-user'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      clusterId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`postgres`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`id`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DatabaseDb`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-postgres-db'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      clusterId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`postgres`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`id`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-db'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` droplet `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Droplet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-droplet'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-droplet'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      size`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'s-1vcpu-1gb'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      region`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'sfo3'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      image`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'ubuntu-20-04-x64'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      vpcUuid`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` vpc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ProjectResources`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'example-project-resources'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      project`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` project`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      resources`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
        postgres`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`urn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        droplet`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`urn
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      dependsOn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` postgres`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` droplet `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` app `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`App`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyStack`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cdk-do-example'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`synth`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`We start by assigning the project, VPC, and droplet to variables. In the `}<inlineCode parentName="p">{`DatabaseCluster`}</inlineCode>{` definition, we add `}<inlineCode parentName="p">{`privateNetworkUuid: [vpc.id](http://vpc.id)`}</inlineCode>{` to place the database in our newly created VPC. Similarly, on the `}<inlineCode parentName="p">{`Droplet`}</inlineCode>{` definition, we place it in the VPC, by adding `}<inlineCode parentName="p">{`vpcUuid: vpc.id`}</inlineCode>{`.`}</p>
    <p>{`Lastly, create a new `}<inlineCode parentName="p">{`ProjectResource`}</inlineCode>{` to assign other resources to the Digital Ocean project. In this small example, we will assign the database and droplet to the project using the `}<inlineCode parentName="p">{`urn`}</inlineCode>{` for each resource. We will wait to assign those until both are created using a Terraform helper `}<inlineCode parentName="p">{`dependsOn`}</inlineCode>{`.`}</p>
    <p>{`With all of that in place you can deploy again. The database and droplet creation take a bit, so be patient. 🙂 Once it has finished, check your Digital Ocean Dashboard to see everything created and ready for use.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "650px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "33.12883435582822%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9ElEQVQY001QCW7DIBDMa9r64D6WG+M4qpO4//9OBThtpBGaBWY0s5d0PaRZqIom3sBvcX1SGQekBqS+Ztmg+vi6/H+6xPXp88504pCpDFRFKoMwCxZ+IgZzP1MzEehnR+dVbNO3tIVDlrYwFQVkqmL10omIgJgdMUzENMBE9J9FFYdyF1Bjc525TtIUIjxTEXOHmEXMzdSM+NS8kxa7PFzeXd5DeWi3huUO/urzjpidKTQLN1OLeXUhwiNmMfeneLn95O2AsEmzcJ3y9agtamaPuevomrfm5hQLyG1VccR6QEpA7sYfI+8hW2d48frncxJ9278TTFXTJTsgpAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Full Example Deployed",
        "title": "Full Example Deployed",
        "src": "/static/4c501a69d17609b1621a03b95d356f40/a6d36/full-example-deployed.png",
        "srcSet": ["/static/4c501a69d17609b1621a03b95d356f40/222b7/full-example-deployed.png 163w", "/static/4c501a69d17609b1621a03b95d356f40/ff46a/full-example-deployed.png 325w", "/static/4c501a69d17609b1621a03b95d356f40/a6d36/full-example-deployed.png 650w", "/static/4c501a69d17609b1621a03b95d356f40/e548f/full-example-deployed.png 975w", "/static/4c501a69d17609b1621a03b95d356f40/3c492/full-example-deployed.png 1300w", "/static/4c501a69d17609b1621a03b95d356f40/c211c/full-example-deployed.png 1502w"],
        "sizes": "(max-width: 650px) 100vw, 650px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p><strong parentName="p">{`*`}{`Make sure you run `}<inlineCode parentName="strong">{`cdktf destroy`}</inlineCode>{` to remove these resources from your account or you will be charged by Digital Ocean.`}{`*`}</strong></p>
    <h2>{`Things To Know`}</h2>
    <p>{`CDKTF is still a young project, so it is changing fast, has limited documentation, and you can run into unclear errors.`}</p>
    <p>{`There are few things that help with the limited documentation. You can read the provider documentation on the `}<a parentName="p" {...{
        "href": "https://registry.terraform.io/providers/digitalocean/digitalocean/latest/docs"
      }}>{`Terraform registry site`}</a>{` and use it as a guide. Also, using a language like Typescript with VSCode there are a lot of code hints, hover info, and signature information. The gif below is an example of what is shown in VSCode when you hover on the problem. Note the missing properties for `}<inlineCode parentName="p">{`DropletConfig`}</inlineCode>{`.`}</p>
    <img {...{
      "src": "https://media.giphy.com/media/Ae5Z300UKAWhqZiHfc/source.gif",
      "alt": "Typescript Annotations"
    }}></img>
    <p>{`When you run into unclear errors, prepend `}<inlineCode parentName="p">{`CDKTF_LOG_LEVEL=debug`}</inlineCode>{` to the deploy and/or destroy commands to get very verbose output.`}</p>
    <h2>{`Wrapping Up`}</h2>
    <p>{`In this post, we used CDKTF to create some basic example resources on Digital Ocean which gives you a good primer to build more complex infrastructure in a language of your choice. You can find the code from this post in this `}<a parentName="p" {...{
        "href": "https://github.com/hintmedia/cdk-do-example"
      }}>{`repo`}</a>{`. If you would like to chat more about CDK or infrastructure as code you can ping me on Twitter `}<a parentName="p" {...{
        "href": "https://twitter.com/natron99"
      }}>{`@natron99`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      